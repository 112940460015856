.ProfileDetails {
  margin: 1em;
}

.Breadcrumb {
  margin: 1em;
}

.Details {
  width: 90%;
  margin: auto;
}

.Divider {
  width:10px;
  height:auto;
  display:inline-block;
}

.Spinner {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ModalButtons {
  margin-top: 20px;
}
