.DeviceForm {
  margin: 1em;
}

.Breadcrumb {
  margin: 1em;
}

.Form {
  width: 80%;
  margin: auto;
}

.Divider {
  width:30px;
  height:auto;
  display:inline-block;
}

.Buttons {
  margin-top: 20px;
}

.Spinner {
  margin-top: 10px;
  margin-bottom: 10px;
}