.Table {
  margin: 1em;
}

.AddButton {
  margin-right: 1em;
}

.Spinner {
  margin-top: 30px;
}
