.BrowserCreate {
  margin: 1em;
}

.Breadcrumb {
  margin: 1em;
}

.ModalButtons {
  margin-top: 20px;
}
