.SearchInputContainer {
  display:flex;
  width: 70%;
  padding-left: 7px;
  padding-top: 10px;
  justify-content: flex-start;
  flex-direction: column;
  text-align: flex-start;
}

.SearchButton {
  display:flex;
  align-items:flex-start;
  padding-left: 7px;
  padding-top: 3px;
}

@media (max-width: 590px) {
  .SearchInputContainer {
    margin-bottom: 1rem;
    width: 70%;
  }
  .SearchButton {
    width: 30%;
  }
}

@media (max-width: 400px) {
  .SearchInputContainer {
    margin-bottom: 1rem;
    width: 60%;
  }
  .SearchButton {
    width: 40%;
  }
}