.navItem {
  color: black;
  font-size: 16px;
}

.rightItem {
  margin-left: auto !important;
}

.navItem:hover {
  background-color: rgba(255, 255, 255, 0.08);
  text-decoration: none;
}

.menuItem {
  text-decoration: none;
  height: 100%;
}

@media (max-width: 1350px) {
  .navItem {
    color: black;
    font-size: 95%;
  }
}
