.Logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.Background {
  background-color: #062F6E;
  position: fixed;
  width: 100%;
  height: 100%;
}

.ContainerAlign {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
  width: 35%;
}

.card {
  width: 100%;
}

.LoginButton {
  margin-bottom: 10px;
}

@media (max-width: 1125px) {
  .ContainerAlign {
    width: 90%;
  }
}

@media (max-width: 499px) {
  .ContainerAlign {
    width: 90%;
  }
}
