.PaginationItemsSelectorInputContainer {
  display:flex;
  flex-direction: column;
  width: 100%;
}

.PaginationItemsDropdown {
  padding-top: 6px;
  position: relative;
  width: 100%;
  padding-right: 7px;
  padding-left: 6px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  margin-bottom: 0 !important;
}
