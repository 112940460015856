.Table {
  margin: 1em;
}

.AddButton {
  display: flex;
  margin-right: 2em;
}

.Spinner {
  margin-top: 30px;
}
